









































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { Offer } from '@/interfaces/offer'
import RI from '@/components/offer-setup/RI.vue'

@Component({
  components: {
    RI
  }
})
export default class Users extends Vue {
  private offerService = new OfferService();

  offers: Offer[] = [];
  offer: Offer = {} as Offer

  state = {
    loading: false
  }

  async mounted() {
    this.state.loading = true
    this.offers = await this.loadOffers()
    this.state.loading = false
  }

  async loadOffers() {
    return this.offerService.all()
  }
}

