




























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'

@Component({})
export default class Users extends Vue {
  private offerService = new OfferService();

  segments: any[] = []

  state = {
    loading: false,
    isFilterOpen: false
  }

  filter = {
    nome: ''
  }

  formState = {
    loading: false
  }

  form = {
    nome: ''
  }

  selected = {}

  async mounted() {
    this.state.loading = true

    this.segments = await this.offerService.businessSegments()

    this.state.loading = false
  }

  get filteredSegments() {
    if (!this.filter.nome) return this.segments

    return this.segments
      .filter(s => s.nome.toLowerCase().indexOf(this.filter.nome.toLowerCase()) > -1)
  }

  setSelected(seg) {
    this.selected = seg
  }

  onFormSubmit() {
    if (!this.form.nome) {
      alert('Nome é um campo obrigatório.')
      return
    }

    this.formState.loading = true

    this.offerService.newBusinessSegment(this.form)
      .then(response => {
        this.segments.push(response)
        this.form.nome = ''

        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `O setor de atuação ${response.nome} foi criado com sucesso.`,
          group: 'form'
        })
      })
      .catch(request => {
        const message = request.response.data && request.response.data.message

        alert(message || 'Erro ao criar segmento. Por favor contate o suporte.')
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onEditSubmit() {
    this.formState.loading = true
    this.offerService.updateBusinessSegment(this.selected)
      .then((response) => {
        this.formState.loading = false
        ;(this.$refs.closeModalBtn as any).click()

        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `O setor de atuação ${response.nome} foi atualizado com sucesso.`,
          group: 'form'
        })
      })
  }
}

