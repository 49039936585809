





















































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop, Watch } from 'vue-property-decorator'
import { Offer, NewFounder } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'
import InputImageFile from '@/components/form/InputImageFile.vue'

@Component({
  components: {
    InputImageFile
  }
})
export default class Founders extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  form = {
    fundadores: [] as NewFounder[]
  }

  selectedFounderToEdit: NewFounder | null = null

  dateNow = Date.now()

  /**
   * Main form state.
   */
  formState: any = {
    loading: false
  }

  errors = {}

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.dateNow = Date.now()
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer && this.offer.fundadores && this.offer.fundadores.length) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer: Offer) {
    const map = offer.fundadores.map((founder) => {
      let empty = this.buildEmptyFounder()
      empty = { ...empty, fundador: { ...empty.fundador, ...founder } }
      return empty
    })

    return {
      fundadores: map
    }
  }

  buildEmptyFounder(): NewFounder {
    return {
      arquivo: null as any,
      fundador: {
        nome: '',
        cargo: '',
        linkedin: '',
        biografia: '',
        nome_arquivo: '',
        tipo: ''
      }
    }
  }

  addNew() {
    const f = this.buildEmptyFounder()
    this.selectedFounderToEdit = f
  }

  removeFounder(founder: NewFounder) {
    const response = window.confirm(
      `Tem certeza que deseja remover o fundador ${founder.fundador.nome}?`
    )

    if (!response) return

    this.formState.loading = true

    this.offerService.deleteFounder(founder.fundador.id as string)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Fundador ${response.nome} foi removido com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao remover fundador.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
        this.selectedFounderToEdit = null
      })
  }

  newFounder(form: NewFounder, offerUrl: string) {
    this.formState.loading = true

    this.offerService
      .newFounder(form, offerUrl)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Fundador ${response.nome} foi criado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao criar fundador.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
        this.selectedFounderToEdit = null
      })
  }

  updateFounder(form: NewFounder) {
    this.formState.loading = true

    this.offerService
      .updateFounder(form, this.offer.url_oferta)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Fundador ${response.nome} foi criado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao criar fundador.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
        this.selectedFounderToEdit = null
      })
  }

  onSubmit() {
    this.formState.loading = true

    if (!this.selectedFounderToEdit) return

    this.selectedFounderToEdit.fundador.id
      ? this.updateFounder(this.selectedFounderToEdit)
      : this.newFounder(this.selectedFounderToEdit, this.offer.url_oferta)
  }

  onFileChange({ file, type, name }) {
    ;(this.selectedFounderToEdit as NewFounder).arquivo = file
    ;(this.selectedFounderToEdit as NewFounder).fundador.tipo = type
    ;(this.selectedFounderToEdit as NewFounder).fundador.nome_arquivo = name
  }
}
