
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InputFile extends Vue {
  @Prop({ required: true }) placeholder!: string

  form: { file: File | null; name: string; type: string; size: number } = {
    file: null,
    name: '',
    type: '',
    size: 0
  }

  onFileChange(e: any) {
    if (!e.target.files.length) return

    this.form.file = e.target.files[0] as File
    this.form.name = this.form.file.name
    this.form.type = this.form.file.type
    this.form.size = (this.form.file.size / 1024 / 1024).toFixed(2) as any

    this.$emit('onFileSelected', this.form)
  }
}
