

























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import { Offer } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'
import EventBus from '@/services/EventBus.service'

interface FormIRPF {
  razao_social: string;
  codigo_irpf?: string;
  cnpj: string;
}

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'irpf-form',
  directives: {
    mask: VueMaskDirective
  }
})
export default class IRPF extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  /** Form with basic infos. */
  form = {
    razao_social: '',
    codigo_irpf: '',
    cnpj: ''
  } as FormIRPF

  errors = {} as Errors

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  async mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer): FormIRPF {
    return {
      razao_social: offer.razao_social,
      codigo_irpf: offer.codigo_irpf,
      cnpj: offer.cnpj
    }
  }

  onFormSubmit() {
    this.updateOffer()
  }

  updateOffer() {
    this.formState.loading = true

    this.offerService
      .update({ ...this.offer, ...this.form })
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}
