







import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop } from 'vue-property-decorator'
import { Offer } from '@/interfaces/offer'
import ElevatorPitchAudio from '@/components/offer-setup/ElevatorPitchAudio.vue'
import OpinionAudio from '@/components/offer-setup/OpinionAudio.vue'

@Component({
  components: {
    ElevatorPitchAudio,
    OpinionAudio
  }
})
export default class Audios extends Vue {
  @Prop({ required: false }) offer!: Offer
}
