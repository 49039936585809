










































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'
import { Offer, ContractType, OfferType } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'
import { VMoney, Money } from 'v-money'

interface CaptacaoForm {
  inicio_captacao?: string;
  fim_captacao?: string;
  meta_esperada?: string;
  valuation_pre_money: number;
  cota_minima?: string;
  porcentagem?: number;
  plano_de_negocio?: string;
  link_grupo?: string;
  tipo_contrato?: string;
  tipo_oferta?: string;
  raio_x?: { tipo_raiox: string; estrelas: number }[];
  url_video?: string;
  diferencial?: string;
  opiniao?: string;
  tamanho_time?: string;
  investimento_colider: number;
}

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'captacao-form',
  directives: {
    mask: VueMaskDirective,
    money: VMoney
  },
  components: {
    Money
  }
})
export default class Captacao extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  ContractType = ContractType
  OfferType = OfferType

  selectedOfferType: { label: string; value: string } = {} as any
  selectContractType: { label: string; value: string } = {} as any

  errors = {} as Errors

  form = {} as CaptacaoForm

  /**
   * Currency configuration to use v-money directive.
   */
  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false /* doesn't work with directive */
  }

  currencyConfig = { separator: '.', decimal: ',' }

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  raioXOptions = [
    {
      tipo_raiox: 'IMPACTO_SOCIOAMBIENTAL',
      label: 'Impacto socioambiental e diversidade',
      estrelas: 0
    },
    { tipo_raiox: 'FATURAMENTO', label: 'Faturamento', estrelas: 0 },
    { tipo_raiox: 'GRAU_INOVACAO', label: 'Grau de Inovação', estrelas: 0 },
    { tipo_raiox: 'ESCALABILIDADE', label: 'Escalabilidade', estrelas: 0 },
    {
      tipo_raiox: 'ESTAGIO_DESENVOLVIMENTO',
      label: 'Estágio de Desenvolvimento do produto',
      estrelas: 0
    }
  ]

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }

    if (this.offer?.tipo_contrato) {
      this.selectContractType = {
        label: ContractType[this.offer.tipo_contrato],
        value: this.offer.tipo_contrato
      }
    }

    if (this.offer?.tipo_oferta) {
      this.selectedOfferType = {
        label: OfferType[this.offer.tipo_oferta],
        value: this.offer.tipo_oferta
      }
    }
  }

  extractProperties(offer): CaptacaoForm {
    return {
      inicio_captacao: offer.inicio_captacao,
      fim_captacao: offer.fim_captacao,
      meta_esperada: offer.meta_esperada,
      cota_minima: offer.cota_minima,
      porcentagem: offer.porcentagem,
      plano_de_negocio: offer.plano_de_negocio,
      link_grupo: offer.link_grupo,
      tipo_contrato: offer.tipo_contrato,
      tipo_oferta: offer.tipo_oferta,
      raio_x: offer.raio_x,
      url_video: offer.url_video,
      diferencial: offer.diferencial,
      opiniao: offer.opiniao,
      tamanho_time: offer.tamanho_time,
      valuation_pre_money: offer.valuation_pre_money,
      investimento_colider: offer.investimento_colider
    }
  }

  onFormSubmit() {
    this.formState.loading = true

    const updateFields = {
      tipo_oferta: (this.selectedOfferType).value,
      tipo_contrato: (this.selectContractType).value
    }

    const form = { ...this.form, ...updateFields } as CaptacaoForm
    const offer = { ...this.offer, ...form }

    this.offerService
      .update(offer)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}
