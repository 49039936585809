






























































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop, Watch } from 'vue-property-decorator'
import { Offer, KPI, KPIParam } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'

@Component({})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  form = {
    kpis: [] as KPI[]
  }

  kpiParamForm: KPIParam = {
    nome: '',
    valor: 0
  }

  selectedKPIToEdit: KPI | Partial<KPI> | null = null

  /**
   * Main form state.
   */
  formState: any = {
    loading: false
  }

  errors = {}

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer: Offer) {
    return {
      kpis: offer.kpis
    }
  }

  buildEmptyKPI(): Partial<KPI> {
    return {
      nome: '',
      descricao: '',
      unidade: 'MONETARIO',
      parametros: [],
      tipo_grafico: null
    }
  }

  addNew() {
    const f = this.buildEmptyKPI()
    this.selectedKPIToEdit = f
  }

  removeKPI(f) {
    const response = window.confirm(
      `Tem certeza que deseja remover o KPI ${f.nome}?`
    )

    if (response) {
      this.form.kpis = this.form.kpis.filter((ff) => ff !== f)
      this.onSubmit()
    }
  }

  addParam() {
    if (!this.selectedKPIToEdit || !this.selectedKPIToEdit.parametros) return

    this.selectedKPIToEdit.parametros.push(Object.assign({}, this.kpiParamForm as KPIParam))
    this.kpiParamForm = {
      nome: '',
      valor: 0
    }
  }

  removeKPIParam(kpi) {
    if (!this.selectedKPIToEdit || !this.selectedKPIToEdit.parametros) return

    this.selectedKPIToEdit.parametros = this.selectedKPIToEdit.parametros.filter((p) => p.nome !== kpi.nome)
  }

  onSubmit() {
    this.formState.loading = true

    if (this.selectedKPIToEdit && !this.selectedKPIToEdit.id) {
      this.form.kpis.push(this.selectedKPIToEdit as KPI)
    }

    const offer = { ...this.offer, ...this.form }

    this.offerService
      .update(offer)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.selectedKPIToEdit = null
        this.formState.loading = false
      })
  }
}
