




































































































































































































































import Vue from 'vue'
import { Quota } from '@/interfaces/quota'
import { Component, Prop } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'

@Component({})
export default class QuotasList extends Vue {
  @Prop({ required: false, default: '' }) urlOferta!: string

  quotas: Quota[] = []

  state = {
    loading: false
  }

  mounted() {
    this.fetchQuotas()
  }

  async fetchQuotas() {
    this.state.loading = true
    const offerService = new OfferService()
    this.quotas = await offerService.quotasByOffer(this.urlOferta)
    this.state.loading = false
  }
}
