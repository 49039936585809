
























































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { ContractType, OfferType } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'
import { NewOfferDTO } from '@/dtos/offer'

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'offer-form',
  directives: {
    mask: VueMaskDirective
  }
})
export default class Basic extends Vue {
  ContractType = ContractType
  OfferType = OfferType

  selectedOfferType: { label: string; value: string } = {} as any
  selectContractType: { label: string; value: string } = {} as any

  offerService: OfferService = new OfferService()

  /** Form with basic infos. */
  form = {
    modalidade: '',
    rentabilidadeValor: undefined,
    rentabilidadePeriodo: '',
    rentabilidadePrazoMeses: undefined,
    porcentagemTipo: '',
    porcentagem: undefined,
    porcentagemMax: undefined,
    porcentagemMin: undefined,
    pagamentoPeriodo: '',
    pagamentoPeriodoDescricao: '',
    nomeOferta: '',
    urlOferta: '',
    cnpj: ''
  } as NewOfferDTO

  errors = {} as Errors

  /**
   * Main form state.
   */
  formState: { [key: string]: any } = {
    loading: false,
    errors: {}
  }

  onFormSubmit() {
    this.saveOffer()
  }

  async saveOffer() {
    this.formState.loading = true

    const updateFields = {
      tipoOferta: (this.selectedOfferType).value,
      tipoContrato: (this.selectContractType).value
    }

    const form = { ...this.form, ...updateFields } as NewOfferDTO

    try {
      const response = await this.offerService.save(form)
      this.$notify({
        type: 'success',
        title: 'Concluído',
        text: `A oferta ${response.nome_oferta} foi criada com sucesso.`,
        group: 'form'
      })

      this.$router.push(`/ofertas/${response.url_oferta}/editar`)
    } catch (responseError) {
      this.$notify({
        type: 'error',
        title: 'Ops!',
        text: 'Erro ao criar oferta.',
        group: 'form'
      })
      this.formState.errors = responseError.errors
    } finally {
      this.formState.loading = false
    }
  }
}
