













import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Offer } from '@/interfaces/offer'
import Settings from './Settings.vue'
import Subconta from './Subconta.vue'
import IuguService from '@/services/Iugu.service'
import EventBus from '@/services/EventBus.service'

@Component({
  name: 'subconta-index',
  components: {
    Settings, Subconta
  }
})
export default class SubcontaIndex extends Vue {
  @Prop({ required: false }) offer!: Offer
  iuguService = new IuguService()

  hasSubAccount = false

  async created() {
    EventBus.$on('reload-offer-subaccount', this.checkIfHasSubAccount)

    this.checkIfHasSubAccount()
  }

  async checkIfHasSubAccount() {
    try {
      const response = await this.iuguService.findSubAccount(this.offer.url_oferta)
      this.hasSubAccount = !!response.account_id && response.is_verified
    } catch (error) {
      this.hasSubAccount = false
    }
  }
}
