



























































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { City, State } from '@/interfaces/address'
import { Prop } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import { ContractType, Offer, OfferType, Sectors } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'
import EventBus from '@/services/EventBus.service'

interface Architecture {
  modalidade: string;
  rentabilidade_valor: number;
  rentabilidade_periodo: string;
  rentabilidade_prazo_meses: number;
  porcentagem_tipo: string;
  porcentagem: number;
  porcentagem_max: number;
  porcentagem_min: number;
  pagamento_periodo: string;
  pagamento_periodo_descricao: string;
}

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'offer-form',
  directives: {
    mask: VueMaskDirective
  }
})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  ContractType = ContractType
  OfferType = OfferType

  selectedOfferType: { label: string; value: string } = {} as any
  selectContractType: { label: string; value: string } = {} as any

  offerService: OfferService = new OfferService()

  // Fix undefined reference
  Sectors = Sectors

  segments: { nome: string } = [] as any

  /**
   * Cities used with select element
   */
  cities = [] as City[]
  selectedCity: City | null = null

  /**
   * States to be used with select element.
   */
  states = [] as State[]
  selectedState: State | null = null

  selectedSectors: string[] = []

  /** Form with basic infos. */
  form = {
    porcentagem_tipo: 'FIXA'
  } as Architecture

  errors = {} as Errors

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  async mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer: Offer): Architecture {
    return {
      modalidade: offer.modalidade,
      rentabilidade_valor: offer.rentabilidade_valor,
      rentabilidade_periodo: offer.rentabilidade_periodo,
      rentabilidade_prazo_meses: offer.rentabilidade_prazo_meses,
      porcentagem_tipo: offer.porcentagem_tipo,
      porcentagem: offer.porcentagem,
      porcentagem_max: offer.porcentagem_max,
      porcentagem_min: offer.porcentagem_min,
      pagamento_periodo: offer.pagamento_periodo,
      pagamento_periodo_descricao: offer.pagamento_periodo_descricao
    }
  }

  onFormSubmit() {
    this.formState.loading = true

    const offer = { ...this.offer, ...this.form }

    this.offerService
      .update(offer)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}
