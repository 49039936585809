






















































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'
import Basic from '@/components/offer-setup/Basic.vue'
import Captacao from '@/components/offer-setup/Captacao.vue'
import Assets from '@/components/offer-setup/Assets.vue'
import Founders from '@/components/offer-setup/Founders.vue'
import Simulator from '@/components/offer-setup/Simulator.vue'
import RI from '@/components/offer-setup/RI.vue'
import Subconta from '@/components/offer-setup/subconta/index.vue'
import SpeedEdit from '@/components/offer-setup/SpeedEdit.vue'
import Documents from '@/components/offer-setup/Documents.vue'
import KPIs from '@/components/offer-setup/KPIs.vue'
import Links from '@/components/offer-setup/Links.vue'
import Audios from '@/components/offer-setup/Audios.vue'
import IRPF from '@/components/offer-setup/IRPF.vue'
import Secundario from '@/components/offer-setup/Secundario.vue'
import Architecture from '@/components/offer-setup/Architecture.vue'

import { Offer } from '@/interfaces/offer'

interface Tab {
  selected: boolean;
  name: string;
  label: string;
}

@Component({
  components: {
    Basic,
    Captacao,
    Assets,
    Founders,
    Simulator,
    RI,
    Subconta,
    SpeedEdit,
    Documents,
    KPIs,
    Links,
    Audios,
    IRPF,
    Secundario,
    Architecture
  }
})
export default class EditOfferPage extends Vue {
  private offerService = new OfferService();

  offer = {} as Offer

  selected: Tab | null = null

  tabs = [
    { selected: false, name: 'architecture', label: 'Arquitetura da Oferta', component: 'Architecture' },
    { selected: true, name: 'basic', label: 'Informações básicas', component: 'Basic' },
    { selected: false, name: 'captacao', label: 'Captação', component: 'Captacao' },
    { selected: false, name: 'irpf', label: 'IRPF', component: 'IRPF' },
    { selected: false, name: 'documentos', label: 'Documentos', component: 'Documents' },
    { selected: false, name: 'audios', label: 'Áudios', component: 'Audios' },
    { selected: false, name: 'kpis', label: 'KPIs', component: 'KPIs' },
    { selected: false, name: 'links', label: 'Links', component: 'Links' },
    { selected: false, name: 'assets', label: 'Imagens', component: 'Assets' },
    { selected: false, name: 'founders', label: 'Fundadores', component: 'Founders' },
    { selected: false, name: 'simulator', label: 'Simulador', component: 'Simulator' },
    { selected: false, name: 'ri', label: 'RI', component: 'RI' },
    { selected: false, name: 'subconta', label: 'Subconta Iugu', component: 'Subconta' },
    { selected: false, name: 'secundario', label: 'Secundário', component: 'Secundario' }
  ]

  async mounted() {
    EventBus.$on('reload-offer-admin', () => this.loadOffer(this.$route.params.url))

    await this.loadOffer(this.$route.params.url)
  }

  async loadOffer(url) {
    this.offer = await this.offerService.findByURL(url)
  }
}

