
















import Vue from 'vue'
import Component from 'vue-class-component'

import 'chart.js/dist/Chart.js'
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'

import { Venture6 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office'
import { Prop } from 'vue-property-decorator'
import { Offer } from '@/interfaces/offer'
import { Investment } from '@/interfaces/investment'

const Chart = (window as any).Chart

@Component({})
export default class InvestmentsByDay extends Vue {
  @Prop({ required: true }) investments!: Investment[];
  @Prop({ required: true }) offer!: Offer;

  $moment;

  investmentsChart: any = null

  campaigns: string[] = []

  labels: any[] = []

  datasets: any[] = []

  formState = {
    loading: false
  }

  async mounted() {
    this.initInvestmentsChart()

    const { labels, dataset } = this.transformData(this.offer, this.investments)

    this.investmentsChart.data.labels = labels
    this.investmentsChart.data.datasets = [dataset]

    this.investmentsChart.update()
  }

  transformData(offer: Offer, investments: Investment[]) {
    const labels = this.buildLabels(offer)

    const dataset = this.buildDataset(labels, investments)

    return {
      labels,
      dataset
    }
  }

  buildDataset(labels, investments: Investment[]) {
    const data = labels.map((dateString) => {
      const currentDate = this.$moment(dateString, 'DD/MM/YYYY')

      return investments
        .filter((i: Investment) => {
          return this.$moment(i.pagamento.criado, 'DD/MM/YYYY').isSame(currentDate)
        })
        .reduce((acc, cur) => {
          acc += cur.valor
          return acc
        }, 0)
    })

    return {
      data,
      label: 'Valor Investido',
      fill: false,
      pointRadius: 10,
      pointHoverRadius: 15
    }
  }

  buildLabels(offer: Offer) {
    const from = this.$moment(offer.inicio_captacao, 'DD/MM/YYYY')
    const to = this.$moment(offer.fim_captacao, 'DD/MM/YYYY')

    const labels: string[] = []

    while (to.diff(from, 'days') >= 0) {
      labels.push(from.format('DD/MM/YYYY'))

      from.add(1, 'd')
    }

    return labels
  }

  initInvestmentsChart() {
    const ctx = (this.$refs.investmentsChart as any).getContext('2d')

    this.investmentsChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        legend: false,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          colorschemes: {
            scheme: Venture6
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              const label = this.$options.filters?.currency(tooltipItem.yLabel)
              return `${label}`
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawTicks: false,
              display: false
            },
            ticks: {
              fontSize: 16,
              padding: 20
            }
          }],
          yAxes: [{
            ticks: {
              fontSize: 16,
              padding: 20,
              callback: (value) => {
                return this.$options.filters?.currency(value)
              }
            }
          }]
        }
      }
    })
  }
}
