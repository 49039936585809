





















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { User } from '@/interfaces/user'
import { mask } from 'vue-the-mask'
import { Offer, OfferStatus, OfferToCSV } from '@/interfaces/offer'
import { sortBy } from 'underscore'

interface FilterOptions {
  status: OfferStatus | null;
  nome: string | null;
}

@Component({
  components: {
    Pagination,
    JsonExcel
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  private offerService = new OfferService()

  offers: Offer[] = []
  filteredOffers: Offer[] = []

  // Fix lint error.
  OfferToCSV = OfferToCSV
  OfferStatus = OfferStatus

  users: User[] = []

  options = {
    itemsPorPagina: 20,
    pagina: 0
  }

  filter: FilterOptions = {
    nome: null,
    status: null
  }

  state = {
    downloadLoading: false,
    isFilterOpen: true,
    loading: false,
    error: ''
  }

  async mounted() {
    await this.loadOffers()
    this.filteredOffers = this.offers
  }

  async loadOffers() {
    this.state.loading = true

    const response = (await this.offerService.all()) as Offer[]
    this.offers = sortBy(response, 'nome_oferta')

    this.state.loading = false
  }

  async fetchData() {
    return this.filteredOffers
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }

  onFilterReset() {
    this.filter = { nome: null, status: null }
    this.onFilterSubmit()
  }

  onFilterSubmit() {
    this.state.loading = true

    setTimeout(() => {
      this.filteredOffers = this.offers
        .filter((o) => {
          return this.filter.status ? o.status === this.filter.status : true
        })
        .filter((o) => {
          const query = this.filter.nome?.toLowerCase().trim()
          const nome = o.nome_oferta?.toLowerCase().trim() || ''

          return query ? nome.indexOf(query) > -1 : true
        })

      this.state.loading = false
    }, 500)
  }
}
