




























































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { addressService } from '@/services/Address.service'
import { Prop } from 'vue-property-decorator'
import IuguService from '@/services/Iugu.service'
import { Offer } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'
import { IuguSubAccount, BasicSubAccountForm, FullSubAccountForm, IuguBank } from '@/interfaces/iugu'
import EventBus from '@/services/EventBus.service'

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'offer-form',
  directives: {
    mask: VueMaskDirective
  }
})
export default class Subconta extends Vue {
  @Prop({ required: false }) offer!: Offer

  iuguService = new IuguService()

  iuguBanks: IuguBank[] = []
  iuguBankOptions: string[] = []

  basicForm: BasicSubAccountForm = {
    url_oferta: this.offer.url_oferta
  } as BasicSubAccountForm

  fullForm: FullSubAccountForm = {
    city: '',
    state: '',
    account_type: 'Corrente'
  } as FullSubAccountForm

  errors = {} as Errors

  /**
   * Main form state.
   */
  formState: { [key: string]: any } = {
    openFullForm: false,
    loading: false,
    cep: {
      loading: false,
      error: false,
      success: false
    }
  }

  acc: IuguSubAccount | null = null

  async mounted() {
    this.acc = await this.iuguService.findSubAccount(this.offer.url_oferta)

    if (this.acc && !this.acc.is_verified) {
      this.formState.openFullForm = true
    }

    this.iuguBanks = await this.iuguService.banks()
    this.iuguBankOptions = this.iuguBanks.map(b => b.bank_name)
  }

  onBasicFormSubmit() {
    this.formState.loading = true

    const form = { ...this.basicForm }

    form.comission_percent = parseFloat(form.comission_percent).toFixed(2)

    this.iuguService
      .newBasicSubAccount(form)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'As informações básicas de subconta foram salvas com sucesso.',
          group: 'form'
        })

        this.acc = response
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao salvar informações básicas da subconta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => { this.formState.loading = false })
  }

  onFullFormSubmit() {
    this.formState.loading = true

    const form = { ...this.fullForm }

    this.iuguService
      .newFullSubAccount(this.offer.url_oferta, form)
      .then(async() => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: 'A Subconta Iuguoferta foi cadastrada com sucesso.',
          group: 'form'
        })

        this.acc = await this.iuguService.findSubAccount(this.offer.url_oferta)
        EventBus.$emit('reload-offer-subaccount')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => { this.formState.loading = false })
  }

  onCepChange(event) {
    const cep = event.target.value

    if (!cep) return

    this.formState.cep.loading = true

    addressService
      .findAddressByCEP(cep)
      .then((result) => {
        if (result.erro) {
          this.formState.cep.error = true
          this.fullForm.city = ''
          this.fullForm.state = ''
        } else {
          this.fullForm.city = result.localidade
          this.fullForm.state = result.uf

          this.$set(this.fullForm, 'city', result.localidade)
          this.$set(this.fullForm, 'state', result.uf)

          this.formState.cep.error = false
          this.formState.cep.success = true
        }
      })
      .catch(() => {
        this.formState.cep.error = true
      })
      .then(() => {
        this.formState.cep.loading = false
      })
  }
}
