




















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { VMoney } from 'v-money'
import { Prop, Watch } from 'vue-property-decorator'
import { Offer, SimulatorType } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'

interface SimulatorForm {
  tipo_simulador: string;
  valuation_base?: string;
  valuation_otimista?: string;
  link_simulador_investimento?: string;
  valuation_minimo?: string;
  correcao_monetaria?: number;
  participacao_minima?: number;
  fluxo_de_caixa?: string[];
}

@Component({
  name: 'offer-form',
  directives: {
    money: VMoney
  }
})
export default class Simulator extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  SimulatorType = SimulatorType

  /**
   * Simulator properties.
   */
  form = {
    fluxo_de_caixa: {}
  } as SimulatorForm

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  /**
   * Currency configuration to use v-money directive.
   */
  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false /* doesn't work with directive */
  }

  currencyConfig = { separator: '.', decimal: ',' }

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  onUpdate(e: Event) {
    const target = e.target as any
    const index = parseInt(target.id.split('fluxo-de-caixa-')[1]) // returns the index to be updated

    if (this.form.fluxo_de_caixa) {
      this.form.fluxo_de_caixa[index] = target.value
    }
  }

  mounted() {
    if (this.offer && this.offer.simulador) {
      this.form = this.extractProperties(this.offer)
    }

    if (!this.form.fluxo_de_caixa || !this.form.fluxo_de_caixa.length) {
      this.form.fluxo_de_caixa = ['0', '0', '0', '0', '0']
    }
  }

  extractProperties(offer: Offer): SimulatorForm {
    return {
      tipo_simulador: offer.simulador?.tipo_simulador || '',
      valuation_base: offer.simulador?.valuation_base,
      valuation_otimista: offer.simulador?.valuation_otimista,
      link_simulador_investimento: offer.simulador?.link_simulador_investimento,
      valuation_minimo: offer.simulador?.valuation_minimo,
      correcao_monetaria: offer.simulador?.correcao_monetaria,
      participacao_minima: offer.simulador?.participacao_minima,
      fluxo_de_caixa: offer.simulador?.fluxo_de_caixa
    }
  }

  onSubmit() {
    this.formState.loading = true

    const offer = {
      ...this.offer,
      simulador: {
        ...this.form
      }
    }

    this.offerService
      .update(offer)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}
