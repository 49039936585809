









































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop, Watch } from 'vue-property-decorator'
import { Offer, NewAudio } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'
import InputFile from '@/components/form/InputFile.vue'
import EventBus from '@/services/EventBus.service'

@Component({
  components: {
    InputFile
  }
})
export default class ElevatorPitchAudio extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  form: NewAudio = {} as NewAudio

  // Used as key of root element, the change of his value will force a re-render of the entire component.
  dateNow = Date.now()

  formState: any = {
    loading: false
  }

  errors = {}

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.dateNow = Date.now()
    const elevatorPitch = this.getPitchElevatorAudio(cur)

    if (elevatorPitch) {
      this.form = { ...this.form, arquivo: null as any, audio: { ...this.form.audio, ...elevatorPitch } }
    }
  }

  mounted() {
    this.form = { ...this.buildEmptyPitchElevator() }

    const elevatorPitch = this.getPitchElevatorAudio(this.offer)

    if (elevatorPitch) {
      this.form = { ...this.form, audio: { ...this.form.audio, ...elevatorPitch } }
    }
  }

  getPitchElevatorAudio(offer: Offer) {
    if (!offer.audios || !offer.audios.length) return null

    return offer.audios.find(a => a.nome === 'Pitch de Elevador')
  }

  buildEmptyPitchElevator() {
    return {
      audio: {
        nome: 'Pitch de Elevador',
        nome_arquivo: '',
        descricao: 'Um breve áudio onde o empreendedor apresenta sua empresa',
        tipo: ''
      },
      arquivo: null as unknown as File
    }
  }

  updateAudio(audioForm: NewAudio) {
    this.formState.loading = true

    this.offerService
      .updateAudio(audioForm, this.offer.url_oferta)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Áudio ${response.nome} atualizado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')

        return response
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar audio.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  newAudio(audioForm: NewAudio) {
    this.formState.loading = true

    this.offerService
      .newAudio(audioForm, this.offer.url_oferta)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Áudio ${response.nome} cadastrado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')

        return response
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao cadastrar audio.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onSubmit() {
    this.form.audio.id
      ? this.updateAudio(this.form)
      : this.newAudio(this.form)
  }

  onFileSelected({ file, type, name }) {
    this.form.arquivo = file
    this.form.audio.tipo = type
    this.form.audio.nome_arquivo = name
  }
}
