












































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { VMoney } from 'v-money'
import { Offer } from '@/interfaces/offer'
import { Prop, Watch } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'

interface RIForm {
  id?: string;
  link_grupo: string;
  faturamento: number;
  funcionarios: number;
  clientes: number;
  acoes_civis: number;
  evolucao_faturamento: number;
  link_documento: string;
  depoimento: {
    autor: string;
    texto: string;
  };
}

@Component({
  name: 'offer-form',
  directives: {
    money: VMoney
  }
})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  form = {
    depoimento: {}
  } as RIForm

  /**
   * Currency configuration to use v-money directive.
   */
  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false /* doesn't work with directive */
  }

  currencyConfig = { separator: '.', decimal: ',' }

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer && this.offer.RI) {
      this.form = this.extractProperties(this.offer)
    } else {
      this.form = {
        link_grupo: '',
        faturamento: 0,
        funcionarios: 0,
        clientes: 0,
        acoes_civis: 0,
        evolucao_faturamento: 0,
        link_documento: '',
        depoimento: {
          autor: '',
          texto: ''
        }
      }
    }
  }

  extractProperties(offer: Offer) {
    return {
      id: offer.RI?.id,
      link_grupo: offer.RI?.link_grupo,
      faturamento: offer.RI?.faturamento,
      funcionarios: offer.RI?.funcionarios,
      clientes: offer.RI?.clientes,
      acoes_civis: offer.RI?.acoes_civis,
      evolucao_faturamento: offer.RI?.evolucao_faturamento,
      link_documento: offer.RI?.link_documento,
      depoimento: offer.RI?.depoimento
    } as RIForm
  }

  saveRI() {
    this.formState.loading = true

    this.offerService
      .saveRI(this.form, this.offer.url_oferta)
      .then((ri) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `O RI da oferta ${this.offer.nome_oferta} foi criado com sucesso.`,
          group: 'form'
        })

        this.form = ri

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao criar RI da oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  updateRI() {
    this.formState.loading = true

    this.offerService
      .updateRI(this.form, this.offer.url_oferta)
      .then(() => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `O RI da oferta ${this.offer.nome_oferta} foi atualizado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar RI da oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onFormSubmit() {
    if (this.form.id) {
      this.updateRI()
    } else {
      this.saveRI()
    }
  }
}
