









import Vue from 'vue'
import Component from 'vue-class-component'
import Basic from '@/components/offer-setup/Basic.vue'
import NewOffer from '@/components/offer-setup/NewOffer.vue'

@Component({
  components: {
    Basic,
    NewOffer
  }
})
export default class SetupOfferPage extends Vue {}

