










































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import LvColorPicker from 'lightvue/color-picker'

interface Tag {
  nome: string;
  cor: string;
  id?: string;
}

@Component({
  components: {
    LvColorPicker
  }
})
export default class Users extends Vue {
  private offerService = new OfferService();

  tags: Tag[] = []

  state = {
    loading: false,
    isFilterOpen: false
  }

  filter = {
    nome: ''
  }

  formState = {
    loading: false
  }

  form: Tag = {
    id: undefined,
    nome: '',
    cor: ''
  }

  selected: Tag = {
    id: undefined,
    nome: '',
    cor: ''
  }

  async mounted() {
    this.loadTags()
  }

  async loadTags() {
    this.state.loading = true
    this.tags = await this.offerService.tags()
    this.state.loading = false
  }

  setSelected(tag) {
    this.selected = tag
  }

  onFormSubmit() {
    if (!this.form.nome) {
      alert('Nome é um campo obrigatório.')
      return
    }

    this.formState.loading = true

    this.offerService.newTag(this.form)
      .then(response => {
        this.tags.push(response)
        this.form = {
          id: undefined,
          nome: '',
          cor: ''
        }

        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A tag ${response.nome} foi criada com sucesso.`,
          group: 'form'
        })
      })
      .catch(request => {
        const message = request.response.data && request.response.data.message

        alert(message || 'Erro ao criar tag. Por favor contate o suporte.')
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onEditSubmit() {
    this.formState.loading = true
    this.offerService.updateTag(this.selected)
      .then((response) => {
        this.formState.loading = false
        ;(this.$refs.closeModalBtn as any).click()

        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A tag ${response.nome} foi atualizada com sucesso.`,
          group: 'form'
        })
      })
  }
}

