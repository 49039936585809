











































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop, Watch } from 'vue-property-decorator'
import { Offer, Document, NewDocument } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'
import InputFile from '@/components/form/InputFile.vue'
import EventBus from '@/services/EventBus.service'

import sanitize from 'sanitize-filename'

function sanitizeFileName(filename) {
  const extension = filename.split('.').pop()
  const trimmed = filename.trim().replace(/\s+/g, '-')
  const baseFilename = sanitize(trimmed.substr(0, trimmed.lastIndexOf('.')) || trimmed)
  const now = Date.now()

  return `${baseFilename}-${now}.${extension}`
}

@Component({
  components: {
    InputFile
  }
})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  form = {
    documentos: [] as Document[]
  }

  metaForm: { show: boolean; document: any; fileName: string } = {
    show: false,
    fileName: '',
    document: {
      bucket_name: 'oferta-assets'
    }
  }

  selectedDocumentToEdit: Document | NewDocument | null = null

  /**
   * Main form state.
   */
  formState: any = {
    loading: false
  }

  errors = {}

  sanitizeFileName = sanitizeFileName

  @Watch('metaForm.show')
  onShowMetaForm(cur: boolean) {
    if (!cur) return
    this.metaForm.document.blob_name = `${this.offer.url_oferta}/documentos/${this.metaForm.fileName}`
    this.metaForm.document.bucket_name = 'oferta-assets'
    this.$nextTick(() => {
      this.metaForm.document.url = `https://storage.googleapis.com/${this.metaForm.document.blob_name}`
    })
  }

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer: Offer) {
    return {
      documentos: offer.documentos
    }
  }

  buildEmptyDocument(): NewDocument {
    return {
      documento: {
        nome: '',
        nome_arquivo: '',
        tipo: ''
      },
      arquivo: null as unknown as File
    }
  }

  addNew() {
    const f = this.buildEmptyDocument()
    this.selectedDocumentToEdit = f
  }

  removeDocument(f) {
    const response = window.confirm(
      `Tem certeza que deseja remover o documento ${f.documento.nome}?`
    )

    if (!response) return
    this.formState.loading = true

    const offerService = new OfferService()

    offerService
      .deleteDocument(
        (this.selectedDocumentToEdit as NewDocument).documento.id as string
      )
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Documento ${response.nome} foi removido com sucesso.`,
          group: 'form'
        })
        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar documento.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.selectedDocumentToEdit = null
        this.formState.loading = false
      })
  }

  setSelectedDocument(document: Document) {
    const empty = this.buildEmptyDocument()
    empty.documento = { ...empty.documento, ...document }

    this.selectedDocumentToEdit = empty
  }

  updateDocument(document: NewDocument) {
    this.formState.loading = true

    const offerService = new OfferService()

    return offerService
      .updateDocument(document, this.offer.url_oferta)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Documento ${response.nome} foi atualizado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar documento.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.selectedDocumentToEdit = null
        this.formState.loading = false
      })
  }

  newDocument(documentForm: NewDocument) {
    this.formState.loading = true

    const offerService = new OfferService()

    return offerService
      .newDocument(documentForm, this.offer.url_oferta)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Documento ${response.nome} foi criado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar documento.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.selectedDocumentToEdit = null
        this.formState.loading = false
      })
  }

  onSubmit() {
    if (!this.selectedDocumentToEdit) return

    if ((this.selectedDocumentToEdit as any).documento.id) {
      this.updateDocument(this.selectedDocumentToEdit as NewDocument)
    } else {
      this.newDocument(this.selectedDocumentToEdit as NewDocument)
    }
  }

  onFileSelected({ file, type, name }) {
    ;(this.selectedDocumentToEdit as NewDocument).arquivo = file
    ;(this.selectedDocumentToEdit as NewDocument).documento.tipo = type
    ;(this.selectedDocumentToEdit as NewDocument).documento.nome_arquivo = name
  }

  showMetadataForm(documentId) {
    this.metaForm.show = true
    this.metaForm.document = this.offer.documentos.find(d => d.id === documentId)
  }

  closeMetadataForm() {
    this.metaForm.show = false
    this.metaForm.document = null
  }

  onUpdateMetadata() {
    const document = { ...this.metaForm.document }

    this.formState.loading = true
    this.offerService.updateDocumentMetadata(document)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Documento ${response.nome} foi atualizado com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar metadados.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
        this.closeMetadataForm()
      })
  }
}
