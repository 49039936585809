


















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import IuguService from '@/services/Iugu.service'
import { Offer } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'
import { IuguSubAccount } from '@/interfaces/iugu'
import { Money } from 'v-money'

interface PaymentMethodsForm {
  metodos_pagamento: string[];
}

interface DunningStepsForm {
  steps: {
    action: string; day: string;
  }[];
}

interface SettingsForm {
  dias_vencimento_padrao: number;
  multa: {
    habilitado: boolean;
    porcentagem: number;
    valor_fixo: number;
  };
}

@Component({
  name: 'subaccount-settings-form',
  components: {
    Money
  },
  directives: {
    mask: VueMaskDirective
  }
})
export default class SubcontaSettings extends Vue {
  @Prop({ required: false }) offer!: Offer

  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false /* doesn't work with directive */
  }

  iuguService = new IuguService()

  paymentMethodsForm: PaymentMethodsForm = {
    metodos_pagamento: []
  }

  dunningStepsForm: DunningStepsForm = {
    steps: []
  }

  settingsForm: SettingsForm = {
    multa: {}
  } as SettingsForm

  /**
   * Main form state.
   */
  formState: { [key: string]: any } = {
    openFullForm: false,
    loading: false,
    cep: {
      loading: false,
      error: false,
      success: false
    }
  }

  localState = {
    fineType: '',
    loading: false
  }

  acc: IuguSubAccount = {} as IuguSubAccount

  get expire() {
    return this.dunningStepsForm.steps.find(s => s.action === 'expire') || {}
  }

  async mounted() {
    this.acc = await this.iuguService.findSubAccount(this.offer.url_oferta)

    this.paymentMethodsForm.metodos_pagamento = this.buildPaymentMethodsForm(this.acc)
    this.dunningStepsForm.steps = await this.buildDunningStepsForm()
    this.settingsForm = this.buildSettingsForm(this.acc)
  }

  buildSettingsForm(acc: IuguSubAccount): SettingsForm {
    if (acc.multa.habilitado) {
      if (acc.multa.porcentagem >= 0) this.localState.fineType = 'percent'
      if (acc.multa.valor_fixo >= 0) this.localState.fineType = 'fixed'
    }

    return {
      dias_vencimento_padrao: acc.dias_vencimento_padrao,
      multa: {
        ...acc.multa,
        valor_fixo: acc.multa.valor_fixo ? acc.multa.valor_fixo / 100 : acc.multa.valor_fixo
      }
    }
  }

  async buildDunningStepsForm() {
    return this.iuguService.dunningSteps(this.offer.url_oferta)
      .then((steps) => {
        const expire = steps.find(s => s.action === 'expire')
        return [expire]
      })
  }

  onExpireDayChange(days) {
    this.dunningStepsForm.steps = [{ action: 'expire', day: days }]
  }

  @Watch('localState.fineType')
  onFinesByFixedValueChange(value) {
    if (value === 'percent') this.settingsForm.multa.valor_fixo = 0
    if (value === 'fixed') this.settingsForm.multa.porcentagem = 0
  }

  buildPaymentMethodsForm(acc: IuguSubAccount) {
    const arr: string[] = []
    if (acc.pix.habilitado) arr.push('PIX')
    if (acc.boleto.habilitado) arr.push('BOLETO')
    if (acc.cartao.habilitado) arr.push('CARTAO')
    return arr
  }

  async onFormSubmit() {
    try {
      this.localState.loading = true
      await this.onUpdatePaymentMethods()
      await this.onUpdateDunningSteps()
      await this.onUpdateSettings()

      this.$notify({
        type: 'success',
        title: 'Concluído',
        text: 'Configurações de subconta alteradas com sucesso.',
        group: 'form'
      })
    } catch (e) {
      console.log(e)

      this.$notify({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao configurar subconta.',
        group: 'form'
      })
    } finally {
      this.localState.loading = false
    }
  }

  onUpdateSettings() {
    const payload: SettingsForm = {
      ...this.settingsForm,
      multa: {
        ...this.settingsForm.multa,
        valor_fixo: this.settingsForm.multa.valor_fixo * 100
      }
    }
    return this.iuguService.updateSettings(this.offer.url_oferta, payload)
  }

  onUpdateDunningSteps() {
    return this.iuguService.updateDunningSteps(this.offer.url_oferta, this.dunningStepsForm)
  }

  onUpdatePaymentMethods() {
    this.iuguService.updatePaymentMethods(this.offer.url_oferta, this.paymentMethodsForm)
  }
}
