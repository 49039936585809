

























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'
import { Offer, OfferStatus } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'

interface SpeedEditForm {
  status: { label: string; value: string };
  inicio_captacao: string;
  fim_captacao: string;
  visivel: boolean;
  tags: { label: any; value: any }[];
}

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'speed-edit-form',
  directives: {
    mask: VueMaskDirective
  }
})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  OfferStatus = OfferStatus

  /** Form with basic infos. */
  form = {} as SpeedEditForm

  tags: any = []

  errors = {} as Errors

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  async mounted() {
    if (this.offer) {
      this.tags = await this.offerService.tags()
      this.form = this.extractProperties(this.offer)
    }
  }

  get tagOptions() {
    return this.tags.map(t => {
      return { label: t.nome, value: t }
    })
  }

  extractProperties(offer): SpeedEditForm {
    const status = { label: OfferStatus[offer.status], value: offer.status }
    const tags = offer.tags.length
      ? offer.tags.map(t => {
        return { label: t.nome, value: t }
      })
      : []

    return {
      status,
      tags,
      inicio_captacao: offer.inicio_captacao,
      fim_captacao: offer.fim_captacao,
      visivel: offer.visivel
    }
  }

  /**
   * Handles form submit.
   */
  onFormSubmit() {
    if (this.offer.id) {
      this.updateOffer()
    }
  }

  updateOffer() {
    this.formState.loading = true

    const offer: any = {
      ...this.offer,
      ...this.form,
      tags: this.form.tags.map(t => t.value),
      status: (this.form.status as any).value
    }

    this.offerService
      .update(offer)
      .then((response: Offer) => {
        EventBus.$emit('reload-offer-admin', response)
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }
}
