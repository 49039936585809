















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { addressService } from '@/services/Address.service'
import { State } from '@/interfaces/address'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'chart.js/dist/Chart.js'

import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'

import { Investment } from '@/interfaces/investment'

import { groupBy } from 'underscore'

const Chart = (window as any).Chart

@Component({})
export default class InvestmentsByState extends Vue {
  @Prop({ required: true }) investments!: Investment[];

  statesChart: any = null

  states: State[] = [];

  labels: any[] = []
  datasets: any[] = []

  formState = {
    loading: false
  }

  async mounted() {
    this.states = await addressService.states()
    this.initStatesChart()

    const { labels, dataset } = this.transformData(this.investments)

    this.statesChart.data.labels = labels
    this.statesChart.data.datasets = dataset

    this.statesChart.update()
  }

  get total() {
    return this.investments.reduce((acc: number, cur) => {
      acc += cur.valor
      return acc
    }, 0)
  }

  transformData(investments: Investment[]) {
    const allowedStates = this.states.map(s => s.abbr)

    // Removendo investimentos cujo investidor tem o estado zuado do tipo "Paraná" ao invés de "PR"
    const invs = investments.filter(i => allowedStates.includes(i.investidor.endereco.estado))

    const stateAndInvestedValue = invs.map(i => {
      return {
        state: i.investidor.endereco.estado,
        value: i.valor
      }
    })

    const grouped = groupBy(stateAndInvestedValue, 'state')

    const sumOfValues = Object.keys(grouped).map(state => {
      const sum = grouped[state].reduce((acc, cur) => {
        acc += cur.value
        return acc
      }, 0)

      return {
        state,
        sum
      }
    }).sort((a, b) => b.sum - a.sum)

    return {
      labels: sumOfValues.map(s => s.state),
      dataset: [{
        label: 'Estados',
        backgroundColor: '#42a5f5',
        borderColor: '#42a5f5',
        borderWidth: 1,
        data: sumOfValues.map(s => s.sum)
      }]
    }
  }

  initStatesChart() {
    const ctx = (this.$refs.statesChart as any).getContext('2d')

    this.statesChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        legend: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              const percent = (tooltipItem.xLabel / this.total * 100).toFixed(2)
              const label = this.$options.filters?.currency(tooltipItem.xLabel)
              return `${label} - ${percent}%`
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              callback: (value) => {
                return this.$options.filters?.currency(value)
              }
            }
          }]
        }
      }
    })
  }
}
