




















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

@Component({
  components: {
    VueCropper
  }
})
export default class InputImageFile extends Vue {
  @Prop({ required: true }) label!: string

  imageForm: any = {
    dataURL: null,
    file: null,
    cropped: false,
    name: '',
    type: ''
  }

  errors = {}

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false,
    showForm: true
  }

  validate(file: File): { [key: string]: string } {
    const toMb = (kb: number) => parseFloat((kb / 1024 / 1024).toFixed(2))
    const errors: { [key: string]: string } = {}

    const size = toMb(file.size)

    if (size >= 1) {
      errors.size = 'Imagem deve ser menor que 1MB.'
    }

    return errors
  }

  onUseOriginal() {
    this.imageForm.cropped = true
    this.$emit('fileChange', this.imageForm)
  }

  onFileSelected(e: Event) {
    const file = (e.target as any).files[0]

    if (!file) return

    const errors = this.validate(file)

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((e: string) => {
        alert(errors[e])
      })

      return
    }

    this.imageForm.file = file
    this.imageForm.name = file.name
    this.imageForm.type = file.type

    const reader = new FileReader()

    const onLoadFile = (event: Event) => {
      const { result } = event.target as any

      this.$set(this.imageForm, 'dataURL', result)
    }

    reader.addEventListener('load', onLoadFile.bind(this))

    reader.readAsDataURL(this.imageForm.file)
  }

  async cropImage(cropper: any) {
    const fileName = this.imageForm.file.name

    this.imageForm.file = await new Promise((resolve) =>
      cropper.getCroppedCanvas().toBlob(resolve, this.imageForm.file.type)
    )

    this.imageForm.file.name = fileName
    this.imageForm.cropped = true

    const reader = new FileReader()

    reader.addEventListener(
      'load',
      (event: Event) => {
        const { result } = event.target as any
        this.$set(this.imageForm, 'dataURL', result)
      }
    )

    reader.readAsDataURL(this.imageForm.file)

    this.$emit('fileChange', this.imageForm)
  }
}
