










































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import UserService from '@/services/User.service'
import InvestmentService from '@/services/Investment.service'
import Pagination from '@/components/Pagination.vue'
import InvestmentsByState from '@/components/oferta-consolidada/InvestmentsByState.vue'
import InvestmentsByCity from '@/components/oferta-consolidada/InvestmentsByCity.vue'
import InvestmentsByGender from '@/components/oferta-consolidada/InvestmentsByGender.vue'
import InvestmentsByDays from '@/components/oferta-consolidada/InvestmentsByDays.vue'
import JsonExcel from 'vue-json-excel'
import OfferService from '@/services/Offer.service'
import { Pagination as IPagination } from '@/interfaces/pagination'
import { User } from '@/interfaces/user'
import { Offer, ContractType, OfferType } from '@/interfaces/offer'
import { Investment, PaymentStatus, InvestmentToCSV } from '@/interfaces/investment'
import { groupBy, uniq } from 'underscore'
import { mask } from 'vue-the-mask'
import 'chart.js/dist/Chart.js'
import currency from 'currency.js'

interface FilterOptions {
  email: string;
  oferta: string;
  valor: string;
  status: string;
  data: string;
}

@Component({
  components: {
    Pagination,
    JsonExcel,
    InvestmentsByState,
    InvestmentsByCity,
    InvestmentsByGender,
    InvestmentsByDays
  },
  directives: { mask }
})
export default class Users extends Vue {
  OfferType = OfferType
  ContractType = ContractType
  InvestmentToCSV = InvestmentToCSV
  PaymentStatus = PaymentStatus

  $moment;

  private userService = new UserService();
  private investmentService = new InvestmentService();
  private offerService = new OfferService();

  user: User = {} as User

  offer: Offer = {} as Offer

  investments: Investment[] = []

  pageInvestments: Investment[] = []

  pagination: IPagination = {} as IPagination

  options = {
    itemsPorPagina: 20,
    pagina: 0
  }

  filter: FilterOptions = {
    email: '',
    oferta: this.$route.params.url,
    valor: '',
    status: 'PAGO',
    data: ''
  }

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: false,
    error: ''
  }

  myChart: any = null

  async mounted() {
    this.offer = await this.offerService.findByURL(this.$route.params.url)
    this.investments = await this.investmentService.investments({ paginacao: false, oferta: this.offer.url_oferta }).then(response => response.items)

    this.loadPaginated({ ...this.options, ...this.filter })
  }

  loadPaginated(opts) {
    this.state.loading = true

    this.investmentService.investments(opts)
      .then(data => {
        this.pageInvestments = data.items
        this.pagination = data.pagination
      })
      .then(() => {
        this.state.loading = false
      })
  }

  get offerDeadline() {
    const start = this.$moment(this.offer.inicio_captacao, 'DD/MM/YYYY')
    const end = this.$moment(this.offer.fim_captacao, 'DD/MM/YYYY')

    return end.diff(start, 'd')
  }

  get investmentsByPaymentStatus() {
    return groupBy(this.investments, (i) => i.pagamento.status)
  }

  get topInvestment() {
    const paidInvestments = this.investmentsByPaymentStatus.PAGO
    const groupByInvestor = groupBy(paidInvestments, (i) => i.investidor.email)

    const investors = Object.keys(groupByInvestor).map((email: string) => {
      const sumOfInvestments = groupByInvestor[email].reduce((acc, cur) => {
        return acc + cur.valor
      }, 0)

      return {
        nome: groupByInvestor[email][0].investidor.nome,
        email: email,
        valor: sumOfInvestments
      }
    })

    const ordered = investors.sort((a, b) => b.valor - a.valor).slice(0, 10)

    return ordered[0]
  }

  get totalInvestors() {
    const paidInvestments = this.investmentsByPaymentStatus.PAGO

    if (!paidInvestments) return 0

    const investors = paidInvestments.map(i => i.investidor.email)

    return uniq(investors)
  }

  get investmentValueAverage() {
    const paidInvestments = this.investmentsByPaymentStatus.PAGO

    if (!paidInvestments) return 0

    return paidInvestments.reduce((acc, cur) => acc + cur.valor, 0) / paidInvestments.length
  }

  get paidPercent() {
    const meta = currency(this.offer.meta_esperada, {
      separator: '.',
      decimal: ','
    }).value

    const currentPaid = currency(this.offer.valor_investimentos_pagos, {
      separator: '.',
      decimal: ','
    }).value

    return (currentPaid * 100) / meta
  }

  get topPayment() {
    const paidInvestments = this.investmentsByPaymentStatus.PAGO

    if (!paidInvestments) return 0

    const ordered = paidInvestments.sort((a, b) => b.valor - a.valor)
    return ordered[0].valor
  }

  onToPage(n) {
    this.$set(this.options, 'pagina', n)

    const opts = {
      ...this.options,
      ...this.filter
    }

    this.loadPaginated(opts)
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }

  onFilterReset() {
    this.filter = {
      ...this.filter,
      email: '',
      valor: '',
      data: '',
      status: 'PAGO'
    }

    this.options.pagina = 0

    this.loadPaginated({ ...this.filter, ...this.options })
  }

  onFilterSubmit() {
    this.loadPaginated({ ...this.options, ...this.filter, pagina: 0 })
  }

  async fetchData() {
    const opts = {
      ...this.filter,
      paginacao: false
    }

    const response = await this.investmentService.investments(opts).then(data => data.items)
    return response
  }

  get localeDateTime() {
    return `${new Date().toLocaleDateString('pt-BR')}-${new Date().toLocaleTimeString('pt-BR')}`
  }
}

