export interface User {
  cpf: string;
  email: string;
  endereco: UserAddress;
  nome_completo: string;
  estado_civil?: CivilState;
  rg: string;
  sexo: Gender;
  nacionalidade: Citizenship;
  interesse_na_smu: string;
  como_conheceu_smu: string;
  como_conheceu_smu_complemento?: string;
  investimentos_renda_fixa: number;
  investimentos_renda_variavel: number;
  origem: string;
}

export enum Gender {
  MASCULINO = 'Masculino',
  FEMININO = 'Feminino',
  PREFIRO_NAO_DIZER = 'Prefiro não dizer'
}

export enum GenderColors {
  MASCULINO = 'rgb(54, 162, 235)',
  FEMININO = 'rgb(255, 99, 132)',
  PREFIRO_NAO_DIZER = 'rgb(255, 205, 86)'
}

export enum CivilState {
  CASADO = 'Casado(a)',
  SOLTEIRO = 'Solteiro(a)',
  VIUVO = 'Viúvo(a)',
  SEPARADO = 'Separado(a)',
  DIVORCIADO = 'Divorciado(a)'
}

export interface UserAddress {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  estado: string;
  numero: string;
  rua: string;
  telefone: string;
}

export function UserToCSV(moment) {
  return {
    'Nome Completo': 'nome_completo',
    Email: 'email',
    CPF: 'cpf',
    Telefone: 'telefone',
    Endereco: 'rua',
    Bairro: 'bairro',
    Complemento: 'complemento',
    Cidade: 'cidade',
    Estado: 'estado',
    CEP: 'cep',
    Numero: 'numero',
    'Data de Criação': {
      field: 'data_criacao', // nested attribute supported
      callback: (value) => {
        return moment(value).format('DD/MM/YYYY')
      }
    },
    'Hora de Criação': {
      field: 'data_criacao', // nested attribute supported
      callback: (value) => {
        return moment(value).format('HH:mm:ss')
      }
    },
    Sexo: 'sexo',
    RG: 'rg',
    Nacionalidade: 'nacionalidade',
    'Estado Civil': 'estado_civil',
    'Interesse na SMU': 'interesse_na_smu',
    'Investimentos Renda Fixa': 'investimentos_renda_fixa',
    'Investimentos Renda Variavel': 'investimentos_renda_variavel',
    'Como conheceu a SMU?': 'como_conheceu_smu',
    'Como conheceu a SMU Complemento': 'como_conheceu_smu_complemento',
    Origem: 'origem'
  }
}

export enum Citizenship {
  AFEGANE = 'Afegãne',
  SUL_AFRICANA = 'Sul-africana',
  ALBANESA = 'Albanesa',
  ALEMA = 'Alemã',
  ANDORRANA = 'Andorrana',
  ANGOLANA = 'Angolana',
  ANGUILLANA = 'Anguillana',
  DE_ANTARTIDA = 'De antártida',
  ANTIGUANA = 'Antiguana',
  SAUDITA = 'Saudita',
  ARGELINA = 'Argelina',
  ARGENTINA = 'Argentina',
  ARMENIA = 'Armênia',
  ARUBANA = 'Arubana',
  AUSTRALIANA = 'Australiana',
  AUSTRIACA = 'Austríaca',
  AZERBAIJANO = 'Azerbaijano',
  BAHAMENSE = 'Bahamense',
  BARENITA = 'Barenita',
  BENGALI = 'Bengali',
  BARBADIANA = 'Barbadiana',
  BIELO_RUSSA = 'Bielo-russa',
  BELGA = 'Belga',
  BELIZENHA = 'Belizenha',
  BENINENSE = 'Beninense',
  BERMUDIANA = 'Bermudiana',
  BOLIVIANA = 'Boliviana',
  BOSNIA = 'Bósnia',
  BETCHUANA = 'Betchuana',
  BRASILEIRA = 'Brasileira',
  BRUNEIANA = 'Bruneiana',
  BULGARA = 'Búlgara',
  BURQUINES = 'Burquinês',
  BURUNDINESA = 'Burundinesa',
  BUTANESA = 'Butanesa',
  CABO_VERDIANA = 'Cabo-verdiana',
  CAMARONESA = 'Camaronesa',
  CAMBOJANA = 'Cambojana',
  CANADENSE = 'Canadense',
  CANARIO = 'Canário',
  CAZAQUE = 'Cazaque',
  DE_CEUTA_E_MELILLA = 'De ceuta e melilla',
  CHADIANA = 'Chadiana',
  CHILENA = 'Chilena',
  CHINESA = 'Chinesa',
  CIPRIOTA = 'Cipriota',
  CINGAPURIANA = 'Cingapuriana',
  COLOMBIANA = 'Colombiana',
  COMORENSE = 'Comorense',
  NORTE_COREANO = 'Norte-coreano',
  NORTE_COREANA = 'Norte-coreana',
  MARFINENSE = 'Marfinense',
  COSTARRIQUENHA = 'Costarriquenha',
  CROATA = 'Croata',
  CUBANA = 'Cubana',
  CURACAUENSE = 'Curaçauense',
  CHAGOSITANO = 'Chagositano',
  DINAMARQUESA = 'Dinamarquesa',
  DJIBUTIANA = 'Djibutiana',
  DOMINIQUENSE = 'Dominiquense',
  EGIPCIA = 'Egípcia',
  SALVADORENHA = 'Salvadorenha',
  ARABE = 'Árabe',
  EQUATORIANA = 'Equatoriana',
  ERITREIA = 'Eritreia',
  ESLOVACO = 'Eslovaco',
  ESLOVENO = 'Esloveno',
  ESPANHOLA = 'Espanhola',
  NORTE_AMERICANA = 'Norte-americana',
  ESTONIANA = 'Estoniana',
  ETIOPE = 'Etíope',
  FIJIANA = 'Fijiana',
  FILIPINA = 'Filipina',
  FINLANDESA = 'Finlandesa',
  FRANCESA = 'Francesa',
  GABONESA = 'Gabonesa',
  GAMBIANA = 'Gambiana',
  GANENSE = 'Ganense',
  GEORGIANA = 'Georgiana',
  GIBRALTARIANA = 'Gibraltariana',
  INGLESA = 'Inglesa',
  GRANADINA = 'Granadina',
  GREGA = 'Grega',
  GROENLANDESA = 'Groenlandesa',
  GUADALUPENSE = 'Guadalupense',
  GUAMES = 'Guamês',
  GUATEMALTECA = 'Guatemalteca',
  GUERNESIANO = 'Guernesiano',
  GUIANENSE = 'Guianense',
  FRANCO_GUIANENSE = 'Franco-guianense',
  GUINEU_EQUATORIANO_OU_EQUATOGUINEANA = 'Guinéu-equatoriano ou equatoguineana',
  GUINEU_EQUATORIANO = 'Guinéu-equatoriano',
  GUINEENSE = 'Guineense',
  HAITIANA = 'Haitiana',
  HOLANDES = 'Holandês',
  HONDURENHA = 'Hondurenha',
  HONG_KONGUIANA_OU_CHINESA = 'Hong-konguiana ou chinesa',
  HUNGARA = 'Húngara',
  IEMENITA = 'Iemenita',
  DA_ILHA_BOUVET = 'Da ilha bouvet',
  DA_ILHA_DE_ASCENSAO = 'Da ilha de ascensão',
  DA_ILHA_DE_CLIPPERTON = 'Da ilha de clipperton',
  MANES = 'Manês',
  NATALENSE = 'Natalense',
  PITCAIRNENSE = 'Pitcairnense',
  REUNIONENSE = 'Reunionense',
  ALANDES = 'Alandês',
  CAIMANES = 'Caimanês',
  COQUENSE = 'Coquense',
  COOKENSE = 'Cookense',
  FAROENSE = 'Faroense',
  DAS_ILHAS_GEORGIA_DO_SUL_E_SANDWICH_DO_SUL = 'Das ilhas geórgia do sul e sandwich do sul',
  DAS_ILHAS_HEARD_E_MCDONALD = 'Das ilhas heard e mcdonald',
  MALVINENSE = 'Malvinense',
  NORTE_MARIANENSE = 'Norte-marianense',
  MARSHALLINO = 'Marshallino',
  DAS_ILHAS_MENORES_AFASTADAS = 'Das ilhas menores afastadas',
  NORFOLQUINO = 'Norfolquino',
  SALOMONICO = 'Salomônico',
  SEICHELENSE = 'Seichelense',
  TOQUELAUANO = 'Toquelauano',
  TURQUES = 'Turquês',
  VIRGINENSE = 'Virginense',
  INDIANO = 'Indiano',
  INDONESIA = 'Indonésia',
  IRANIANO = 'Iraniano',
  IRAQUIANA = 'Iraquiana',
  IRLANDESA = 'Irlandesa',
  ISLANDESA = 'Islandesa',
  ISRAELENSE = 'Israelense',
  ITALIANA = 'Italiana',
  JAMAICANA = 'Jamaicana',
  JAPONESA = 'Japonesa',
  CANALINA = 'Canalina',
  JORDANIANA = 'Jordaniana',
  KIRIBATIANA = 'Kiribatiana',
  KOSOVAR = 'Kosovar',
  KUWAITIANO = 'Kuwaitiano',
  LAOSIANA = 'Laosiana',
  LESOTA = 'Lesota',
  LETAO = 'Letão',
  LIBANESA = 'Libanesa',
  LIBERIANA = 'Liberiana',
  LIBIA = 'Líbia',
  LIECHTENSTEINIENSE = 'Liechtensteiniense',
  LITUANA = 'Lituana',
  LUXEMBURGUESA = 'Luxemburguesa',
  MACAUENSE = 'Macauense',
  MACEDONIO = 'Macedônio',
  MALGAXE = 'Malgaxe',
  MALAIA = 'Malaia',
  MALAUIANO = 'Malauiano',
  MALDIVIA = 'Maldívia',
  MALINESA = 'Malinesa',
  MALTESA = 'Maltesa',
  MARROQUINA = 'Marroquina',
  MARTINIQUENSE = 'Martiniquense',
  MAURICIANA = 'Mauriciana',
  MAURITANA = 'Mauritana',
  MAIOTENSE = 'Maiotense',
  MEXICANA = 'Mexicana',
  MICRONESIA = 'Micronésia',
  MOCAMBICANA = 'Moçambicana',
  MOLDAVO = 'Moldavo',
  MONEGASCA = 'Monegasca',
  MONGOL = 'Mongol',
  MONTENEGRA = 'Montenegra',
  MONTSERRATIANO = 'Montserratiano',
  BIRMANES = 'Birmanês',
  NAMIBIA = 'Namíbia',
  NAURUANA = 'Nauruana',
  NEPALESA = 'Nepalesa',
  NICARAGUENSE = 'Nicaraguense',
  NIGERINA = 'Nigerina',
  NIGERIANA = 'Nigeriana',
  NIUEANA = 'Niueana',
  NORUEGUESA = 'Norueguesa',
  CALEDONIA = 'Caledônia',
  NEOZELANDESA = 'Neozelandesa',
  OMANI = 'Omani',
  DOS_PAISES_BAIXOS_CARIBENHOS = 'Dos países baixos caribenhos',
  PALAUENSE = 'Palauense',
  PALESTINO = 'Palestino',
  ZONA_DO_CANAL_DO_PANAMA = 'Zona do canal do panamá',
  PAUASIA = 'Pauásia',
  PAQUISTANESA = 'Paquistanesa',
  PARAGUAIA = 'Paraguaia',
  PERUANA = 'Peruana',
  FRANCO_POLINESIA = 'Franco-polinésia',
  POLONESA = 'Polonesa',
  PORTORRIQUENHA = 'Portorriquenha',
  PORTUGUESA = 'Portuguesa',
  CATARENSE = 'Catarense',
  QUENIANO = 'Queniano',
  QUIRGUIZ = 'Quirguiz',
  CENTRO_AFRICANA = 'Centro-africana',
  CONGOLESA = 'Congolesa',
  DOMINICANA = 'Dominicana',
  TCHECO = 'Tcheco',
  ROMENA = 'Romena',
  RUANDESA = 'Ruandesa',
  RUSSA = 'Russa',
  SAARIANO = 'Saariano',
  PEDRO_MIQUELONENSE = 'Pedro-miquelonense',
  SAMOANA = 'Samoana',
  SAMOANO = 'Samoano',
  SAMARINES = 'Samarinês',
  HELENENSE = 'Helenense',
  SANTA_LUCENSE = 'Santa-lucense',
  SAO_BARTOLOMEENSE = 'São-bartolomeense',
  SAO_CRISTOVENSE = 'São-cristovense',
  SAO_MARTINHENSE = 'São-martinhense',
  SAO_TOMENSE = 'São-tomense',
  SAO_VICENTINO = 'Sao-vicentino',
  SENEGALESA = 'Senegalesa',
  LEONESA = 'Leonesa',
  SERVIA = 'Sérvia',
  SIRIA = 'Síria',
  SOMALI = 'Somali',
  CINGALESA = 'Cingalesa',
  SUAZI = 'Suazi',
  SUDANESA = 'Sudanesa',
  SUL_SUDANES = 'Sul-sudanês',
  SUECA = 'Sueca',
  SUICA = 'Suíça',
  SURINAMESA = 'Surinamesa',
  SVALBARDENSE = 'Svalbardense',
  TADJIQUE = 'Tadjique',
  TAILANDESA = 'Tailandesa',
  TAIWANES = 'Taiwanês',
  TANZANIANA = 'Tanzaniana',
  DO_TERRITORIO_BRITANICO_DO_OCEANO_INDICO = 'Do território britânico do oceano índico',
  DO_TERRITORIOS_DO_SUL_DA_FRANCA = 'Do territórios do sul da frança',
  TIMORENSE = 'Timorense',
  TOGOLESA = 'Togolesa',
  TONGANESA = 'Tonganesa',
  TRINITARIO_TOBAGENSE = 'Trinitário-tobagense',
  TRISTANITA = 'Tristanita',
  TUNISIANA = 'Tunisiana',
  TURCOMANA = 'Turcomana',
  TURCA = 'Turca',
  TUVALUANA = 'Tuvaluana',
  UCRANIANA = 'Ucraniana',
  UGANDENSE = 'Ugandense',
  URUGUAIA = 'Uruguaia',
  UZBEQUE = 'Uzbeque',
  VANUATUENSE = 'Vanuatuense',
  VATICANO = 'Vaticano',
  VENEZUELANA = 'Venezuelana',
  VIETNAMITA = 'Vietnamita',
  WALLIS_FUTUNENSE = 'Wallis-futunense',
  ZAMBIANA = 'Zambiana',
  ZIMBABUANA = 'Zimbabuana'
}
