import { render, staticRenderFns } from "./consolidado.vue?vue&type=template&id=7021aea8&scoped=true&"
import script from "./consolidado.vue?vue&type=script&lang=ts&"
export * from "./consolidado.vue?vue&type=script&lang=ts&"
import style0 from "./consolidado.vue?vue&type=style&index=0&id=7021aea8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7021aea8",
  null
  
)

export default component.exports