















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Prop, Watch } from 'vue-property-decorator'
import { Offer, Link } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'
import EventBus from '@/services/EventBus.service'

@Component({})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  form = {
    links: [] as Link[]
  }

  selectedLinkToEdit: Link | Partial<Link> | null = null

  /**
   * Main form state.
   */
  formState: any = {
    loading: false
  }

  errors = {}

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer: Offer) {
    return {
      links: offer.links
    }
  }

  buildEmptyLink(): Partial<Link> {
    return {
      nome: '',
      url: '',
      codigo_embed: '',
      embed: false,
      tipo: null
    }
  }

  addNew() {
    const f = this.buildEmptyLink()
    this.selectedLinkToEdit = f
  }

  removeLink(f) {
    const response = window.confirm(
      `Tem certeza que deseja remover o Link ${f.nome}?`
    )

    if (response) {
      this.form.links = this.form.links.filter((ff) => ff !== f)
      this.onSubmit()
    }
  }

  onSubmit() {
    this.formState.loading = true

    if (this.selectedLinkToEdit && !this.selectedLinkToEdit.id) {
      this.form.links.push(this.selectedLinkToEdit as Link)
    }

    const offer = { ...this.offer, ...this.form }

    this.offerService
      .update(offer)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })

        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.selectedLinkToEdit = null
        this.formState.loading = false
      })
  }
}
