





































import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import { Gender, GenderColors } from '@/interfaces/user'
import { State } from '@/interfaces/address'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'chart.js/dist/Chart.js'

import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
import { Genesis6 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office'

import { Investment } from '@/interfaces/investment'

import { uniq, groupBy } from 'underscore'
import UserService from '@/services/User.service'

const Chart = (window as any).Chart

@Component({})
export default class InvestmentsByState extends Vue {
  @PropSync('investments', { type: Array }) localInvestments!: Investment[];

  private userService = new UserService();

  investmentsWithInvestorInfo: Investment[] = [];

  genderChart: any = null

  states: State[] = [];

  labels: any[] = []
  datasets: any[] = []

  state = {
    loading: false,
    percent: 0
  }

  formState = {
    loading: false
  }

  get total() {
    return this.localInvestments.reduce((acc: number, cur) => {
      acc += cur.valor
      return acc
    }, 0)
  }

  async loadInvestments(originalInvestments: Investment[]): Promise<Investment[]> {
    const offer = originalInvestments[0].url_oferta

    this.state.loading = true

    const invs = [...originalInvestments]

    for (let index = 0; index < invs.length; index++) {
      const inv = invs[index]
      const p = await this.userService.profile(inv.investidor.email);

      // Por algum motivo se eu setar direto em `investidor` ele altera o array original passado lá na pagina consolidado.vue
      // Bizarro demais..
      (inv as any).investidor_completo = p

      this.state.percent = (index / invs.length) * 100
    }

    localStorage.setItem(`${offer}_investments`, JSON.stringify(invs))

    this.state.loading = false

    return invs
  }

  async mounted() {
    this.investmentsWithInvestorInfo = await this.loadInvestments([...this.localInvestments])

    this.initCitiesChart()

    const { labels, dataset } = this.transformData(this.investmentsWithInvestorInfo)

    this.genderChart.data.labels = labels
    this.genderChart.data.datasets = dataset

    this.genderChart.update()
  }

  transformData(investments: Investment[]) {
    const emailAndGender = uniq(investments.map(i => {
      return {
        value: i.valor,
        email: i.investidor.email,
        gender: (i as any).investidor_completo.sexo || 'Não cadastrado'
      }
    }), (a) => a.email) // Removendo duplicados para não contar duas vezes

    const grouped = groupBy(emailAndGender, 'gender')

    const valuesByGender = Object.keys(grouped).map((gender) => {
      const sum = grouped[gender].reduce((acc, cur) => {
        acc += cur.value
        return acc
      }, 0)

      return {
        sum,
        gender
      }
    })

    return {
      labels: valuesByGender.map(s => Gender[s.gender] || 'Não cadastrado'),
      dataset: [{
        label: 'Sexo',
        data: valuesByGender.map(s => s.sum),
        backgroundColor: valuesByGender.map(g => GenderColors[g.gender] || 'rgb(201, 203, 207)')
      }]
    }
  }

  initCitiesChart() {
    const ctx = (this.$refs.genderChart as any).getContext('2d')

    this.genderChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        plugins: {
          colorschemes: {
            scheme: Genesis6
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const idx = tooltipItem.index
              const value = data.datasets[0].data[idx]

              const percent = (value / this.total * 100).toFixed(2)
              const label = this.$options.filters?.currency(value)
              return `${label} - ${percent}%`
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true
      }
    })
  }
}
