









































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import { Offer } from '@/interfaces/offer'
import EventBus from '@/services/EventBus.service'
import { Money } from 'v-money'
import QuotasList from '@/components/lists/QuotasList.vue'

interface FormSecundario {
  valor_unitario_da_cota: number;
  nome_emissor: string;
  empresa_responsavel: string;
  receita_bruta: number;
  lucro_bruto: number;
  margem_bruta: number;
  ebitda: number;
  lucro_liquido: number;
  margem_liquida: number;
}

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'secundario-form',
  components: {
    Money,
    QuotasList
  }
})
export default class IRPF extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  /** Form with basic infos. */
  form = {
    valor_unitario_da_cota: 0
  } as FormSecundario

  errors = {} as Errors

  quotas = []

  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false /* doesn't work with directive */
  }

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  async mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
      this.findAllQuotas()
    }
  }

  extractProperties(offer): FormSecundario {
    return {
      valor_unitario_da_cota: offer.valor_unitario_da_cota || 0,
      nome_emissor: offer.nome_emissor || '',
      empresa_responsavel: offer.empresa_responsavel || '',
      receita_bruta: offer.receita_bruta || 0,
      lucro_bruto: offer.lucro_bruto || 0,
      margem_bruta: offer.margem_bruta || 0,
      ebitda: offer.ebitda || 0,
      lucro_liquido: offer.lucro_liquido || 0,
      margem_liquida: offer.margem_liquida || 0
    }
  }

  onFormSubmit() {
    this.updateOffer()
  }

  updateOffer() {
    this.formState.loading = true

    this.offerService
      .update({ ...this.offer, ...this.form })
      .then((response) => {
        this.successToast(`A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`)
        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.errorToast(message)
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  onConvertToQuotas() {
    const accept = window.confirm('Tem certeza que deseja realizar a conversão? Esta ação é irreversível')

    if (!accept) return

    this.formState.loading = true

    this.offerService.convertInvestmentsToQuotas(this.offer.url_oferta)
      .then((response) => {
        this.successToast(`A conversão para ${this.offer.nome_oferta} foi realizada com sucesso.`)
        this.findAllQuotas()
        EventBus.$emit('reload-offer-admin', response)
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao realizar a conversão.'
        this.errorToast(message)
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  async findAllQuotas() {
    this.formState.loading = true
    this.quotas = await this.offerService.quotasByOffer(this.offer.url_oferta)
    this.formState.loading = false
  }

  successToast(msg: string) {
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: msg,
      group: 'form'
    })
  }

  errorToast(msg: string) {
    this.$notify({
      type: 'error',
      title: 'Ops!',
      text: msg,
      group: 'form'
    })
  }
}
