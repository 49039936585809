






































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { Offer } from '@/interfaces/offer'
import { Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/services/EventBus.service'
import InputImageFile from '@/components/form/InputImageFile.vue'

interface AssetsForm {
  url_logo: string;
  url_cover: string;
}

@Component({
  components: {
    InputImageFile
  }
})
export default class Assets extends Vue {
  @Prop({ required: false }) offer!: Offer

  dateNow: number = Date.now()

  logoForm: any = {
    bucket: 'oferta-assets',
    pasta: `${this.offer.url_oferta}/imagens`,
    nome: '',
    tipo: '',
    arquivo: false
  }

  coverForm: any = {
    bucket: 'oferta-assets',
    pasta: `${this.offer.url_oferta}/imagens`,
    nome: '',
    tipo: '',
    arquivo: false
  }

  form = {} as AssetsForm

  errors = {}

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false,
    showLogoForm: true,
    showCoverForm: true
  }

  offerService = new OfferService()

  @Watch('offer')
  onOfferChange(cur: Offer) {
    this.form = this.extractProperties(cur)
  }

  mounted() {
    if (this.offer) {
      this.form = this.extractProperties(this.offer)
    }
  }

  extractProperties(offer: Offer): AssetsForm {
    return {
      url_logo: offer.url_logo,
      url_cover: offer.url_cover
    }
  }

  onLogoChange({ file, type, name }) {
    this.logoForm.arquivo = file
    this.logoForm.tipo = type
    this.logoForm.nome = name
  }

  onCoverChange({ file, type, name }) {
    this.coverForm.arquivo = file
    this.coverForm.tipo = type
    this.coverForm.nome = name
  }

  upload(payload): Promise<{ url: string }> {
    return this.offerService.storageUpload(payload)
  }

  async onFormSubmit() {
    if (!this.logoForm.arquivo && !this.coverForm.arquivo) return

    let offer = { ...this.offer }
    let logoUploaded: any = null
    let coverUploaded: any = null

    this.formState.loading = true

    if (this.logoForm.arquivo) {
      logoUploaded = await this.upload(this.logoForm)
      offer = { ...offer, url_logo: logoUploaded.url }
    }

    if (this.coverForm.arquivo) {
      coverUploaded = await this.upload(this.coverForm)
      offer = { ...offer, url_cover: coverUploaded.url }
    }

    await this.offerService.update(offer)
      .then((response) => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `Oferta ${response.nome_oferta} atualizada com sucesso.`,
          group: 'form'
        })
        EventBus.$emit('reload-offer-admin')
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
        this.dateNow = Date.now()
      })
    // this.formState.loading = true

    // if (this.logoForm.cropped && this.logoForm.file && this.logoForm.dataURL) {
    //   const logoUrl = await this.offerService.uploadImage(this.logoForm.file)

    //   this.form.url_logo = logoUrl
    // }

    // if (
    //   this.coverForm.cropped &&
    //   this.coverForm.file &&
    //   this.coverForm.dataURL
    // ) {
    //   const coverUrl = await this.offerService.uploadImage(this.coverForm.file)

    //   this.form.url_cover = coverUrl
    // }

    // const offer = { ...this.offer, ...this.form } as Offer

    // this.offerService
    //   .update(offer)
    //   .then((response: Offer) => {
    //     alert('Oferta atualizada com sucesso.')
    //     EventBus.$emit('reload-offer-admin', response)

    //     this.formState.showLogoForm = false
    //     this.formState.showCoverForm = false
    //   })
    //   .catch((request) => {
    //     alert(request.response.data)
    //   })
    //   .then(() => {
    //     this.formState.loading = false
    //     this.logoForm = {
    //       file: null,
    //       dataURL: null,
    //       cropped: false
    //     }

    //     this.coverForm = {
    //       file: null,
    //       dataURL: null,
    //       cropped: false
    //     }
    //   })
  }
}
